import "./App.css";
import "./responsive/breakpoint-1160px.css";
import "./responsive/breakpoint-992px.css";
import "./responsive/breakpoint-768px.css";
import "./responsive/breakpoint-576px.css";
import "./responsive/breakpoint-480px.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Outlet,
} from "react-router-dom";
import React, { useLayoutEffect, lazy, Suspense, useEffect } from "react";
import { startTransition } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";



// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import { LOGOUT } from "./actions/types";

library.add(fas);

const LoadingPage = lazy(() => import("./components/LoadingPage"));
const Sidebar = lazy(() => import("./components/Sidebar"));
const Home = lazy(() => import("./components/Home"));
const Packages = lazy(() => import("./components/Packages"));
const Subscriptions = lazy(() => import("./components/Subscriptions"));
const Class = lazy(() => import("./components/Class"));
const Note = lazy(() => import("./components/Note"));
const AddSubscription = lazy(() => import("./components/AddSubscription"));
const AddUser = lazy(() => import("./components/AddUser/AddUser"));
const Successful = lazy(() => import("./components/Successful"));
const Error = lazy(() => import("./components/Error"));
const ClassList = lazy(() => import("./components/ClassList"));
const GroupList = lazy(() => import("./components/GroupList"));
const Notifications = lazy(() => import("./components/Notifications"));
const ChangePassword = lazy(() => import("./components/ChangePassword"));
const Login = lazy(() => import("./components/Login"));
const Expenses = lazy(() => import("./components/Expenses/Expenses"));
const Alert = lazy(() => import("./components/Alert"));
const TrainerPayment = lazy(() =>
  import("./components/TrainerPayment/TrainerPayment")
);
const Crm = lazy(() => import("./components/CRM/Crm"));
const PrivateRoute = lazy(() => import("./components/routing/PrivateRoute"));

// cookies
const addCookie = () => {
  const cookieName = "plesk_technical_domain";
  const cookieValue = "1";

  if (!document.cookie.includes(`${cookieName}=${cookieValue}`)) {
    document.cookie = `${cookieName}=${cookieValue}`;
  }
};

function App() {


  
  const token = localStorage.token;
  if (token) {
    setAuthToken(token);

    if (!store.getState().auth.isAuthenticated) {
      store.dispatch(loadUser());
    }
  }
  window.addEventListener("storage", () => {
    // if (!localStorage.token) store.dispatch({ type: LOGOUT });
  });




  // (2 saat)
  const inactivityTimeout = 2 * 60 * 60 * 1000;

  // hereketsiz olduqda bagla
  function checkInactivity() {
    const lastInteraction = parseInt(localStorage.getItem("lastInteraction"));
    if (Date.now() - lastInteraction > inactivityTimeout) {
      store.dispatch({ type: LOGOUT });
    }
  }

  // Her saniyye hereketsizliyi yoxlamaq uchun
  setInterval(checkInactivity, 1000);

  //
  function resetInactivityTimer() {
    localStorage.setItem("lastInteraction", Date.now().toString());
  }

  //
  document.addEventListener("mousemove", resetInactivityTimer);
  document.addEventListener("keydown", resetInactivityTimer);

  // Sehife yuklenende sifirla
  resetInactivityTimer();

  useEffect(() => {
    addCookie();
  }, []);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route
                path="/"
                element={
                  <Wrapper>
                    <Sidebar />
                    <Alert />
                    <Outlet />
                  </Wrapper>
                }
              >
                <Route index element={<Home />} />
                <Route
                  path="packages"
                  element={<PrivateRoute component={Packages} />}
                />
                <Route
                  path="subscriptions"
                  element={<PrivateRoute component={Subscriptions} />}
                />
                <Route
                  path="class"
                  element={<PrivateRoute component={Class} />}
                />
                <Route
                  path="note"
                  element={<PrivateRoute component={Note} />}
                />
                <Route
                  path="addsubscription"
                  element={<PrivateRoute component={AddSubscription} />}
                />
                 <Route
                  path="adduser"
                  element={<PrivateRoute component={AddUser} />}
                />
                <Route
                  path="successful"
                  element={<PrivateRoute component={Successful} />}
                />
                <Route
                  path="error"
                  element={<PrivateRoute component={Error} />}
                />
                <Route
                  path="classlist"
                  element={<PrivateRoute component={ClassList} />}
                />
                <Route
                  path="grouplist"
                  element={<PrivateRoute component={GroupList} />}
                />
                <Route
                  path="notifications"
                  element={<PrivateRoute component={Notifications} />}
                />
                <Route path="expenses" element={<PrivateRoute component={Expenses} />} />
                <Route path="crm" element={<PrivateRoute component={Crm} />} />
                <Route
                  path="trainerpayment"
                  element={<PrivateRoute component={TrainerPayment} />}
                />
                <Route
                  path="changepassword"
                  element={<PrivateRoute component={ChangePassword} />}
                />
                {/* <Route path="*" element={<Alert />} /> */}
              </Route>
              <Route path="/login" element={<Login />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
